table thead {
    background-color: yellow;
}

table td {
    font-size: 15px;
}

table tr:hover {
    background: yellow;
    cursor: pointer;
}

.sub_header {
    text-decoration: underline;
    margin-top: 25px;
}

.borderi {
    border: 1px solid #000;
}


/**
 * ##########################
 * ### Navbar Designs
 */
.navbar-brand {
    color: black;

    img {
        height: 100%;
        width: auto;
    }
}

.navbar-default .navbar-brand {
    color: black;
}
.navbar-default .navbar-nav>li:hover {
    background-color: yellow;
}

/* Link */
.navbar-default .navbar-nav>li>a {
    color: black;
}

.navbar-default .navbar-nav>li>a:hover,
.navbar-default .navbar-nav>li>a:focus {
    text-decoration: none !important;
}

.navbar-default .navbar-nav .nav-active {
    color: black;
    background-color: yellow;

    &:focus {
        color: black;
        background-color: yellow;
        outline: unset !important;
    }
}


.navbar-default {
    background-color: white;
    border-color: #e7e7e7;
}

// paddings and margines
.mt-1 {
    margin-top: 5px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-3 {
    margin-top: 15px;
}

.ml-1 {
    margin-left: 5px;
}

.ml-2 {
    margin-left: 10px;
}

.ml-3 {
    margin-left: 15px;
}

.mr-1 {
    margin-right: 5px;
}

.mr-2 {
    margin-right: 10px;
}

.mr-3 {
    margin-right: 15px;
}

.d-block {
    display: block;
}

.show-modal {
    padding-top: 40px;
    display: block;
    opacity: 1;
    background-color: 1p
}

// Pages
.body {
    min-height: 80vh;
}

.footer {
    text-align: center;
    padding-top: 10px;
    border-top: 1px solid #e7e7e7;
}

// Transitions
.fade-in-enter {
    opacity: 0.01;
    transition: opacity 400ms ease-in;
}

.fade-in-enter.fade-in-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
}

.form-error {
    color: red;
    margin-top:7px;
}
